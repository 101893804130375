import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';


const headerColor = '#ae1010';

const SlotsBayPage = ({ data, uri }) => (
  <>
    <SEO
      title="New Online Casino That Accept Neosurf 2024 - 777SlotsBay"
      description="Check out the brand-new online casino accepting Neosurf payment method – 777SlotsBay Casino. Exclusive Neosurf Welcome Bonus revealed."
      canonical={uri}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="http://record.777slotsbay-affiliate.com/text/c863cf75000019000002000000000001000001000001" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="New Neosurf friendly Online Casino – 777SlotsBay" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller textColorDark">Brand New Online Casino Accepting Neosurf - 777SlotsBay</h1>
            <p className="textColorLight">There’s a new player in the online casino town and it’s not fooling around. The name of that player? <strong>777SlotsBay</strong>! Don’t worry, you’ll still get all the features you are used to when playing on a top gambling platform, from thousands of slots, table games, video poker, scratch cards, bingo, and LIVE dealer games, to top-notch security and intuitive user interface. You’ll also get amazing new bonuses with a genuine chance to boost your bankroll, including <strong>exclusive Neosurf Casino Bonus</strong>.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">777SlotsBay’s Exclusive Neosurf Welcome Bonus</h2>
            <ul className="textColorDark">
              <li>100% up to 100 USD/EUR &amp; x no-wagering FREE spins</li>
              <li>Use the code: NEO100+100</li>
              <li>The minimum deposit is 20 USD/EUR</li>
              <li>Rollover is times thirty (x30)</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="http://record.777slotsbay-affiliate.com/text/c863cf75000019000002000000000001000001000001" target="_blank" rel="noopener noreferrer">TO 777 SLOTS BAY CASINO</a>
            </p>
            <p>Simply go to cashier, select your deposit method and follow instructions to enjoy this amazing Welcome Package deal!</p>
            <p>777SlotsBay is a new player in the online casino business set out to achieve some great things. However, this doesn’t mean the team behind the platform lacks in experience, on the contrary. The experts at 777SlotsBay have a lot of history in the gambling niche setting up this <strong>top new online casino on the right path</strong>, from the getgo by bringing to your screen a wide selection of games, convenience and speed no matter the device, plus bulletproof security &amp; privacy so you can deposit &amp; withdraw with ease.</p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="Exclusive Neosurf Casino Bonus" />
        </div>
      </div>
    </div>
  </>
)

export default SlotsBayPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/777slotsbay/New-Neosurf-friendly-Online-Casino-777SlotsBay.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/777slotsbay/Exclusive-Neosurf-Casino-Bonus.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
